import SwiperComponent from "../shared/components/swiper.component"
import { menuItemsData } from "../shared/data/menuItems.data"
import { MenuProps } from "../shared/interfaces/menuItem.interface"
import LinkBoxComponent from "../shared/components/linkBox.component"
import DividerComponent from "../shared/components/divider.component"
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  return (
    <div className="home-page">
      <div className="page-wrapper">

        <section className="top-section mb-16">
          <div className="section-wraper border-b border-white">
            <div className="content">
              <SwiperComponent />
            </div>
          </div>
        </section>

        <section className="product-line-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Ligne de produit" />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-10">
                {/* <div className="col-span-1 sm:col-span-1 md:col-span-3 lg:col-span-3">
                  <LinkBoxComponent src={require('./../assets/media/images/KITCHEN9.jpg')} />
                </div> */}
                <div className="col-span-1 sm:col-span-1 md:col-span-6 lg:col-span-6">
                  <LinkBoxComponent title="focus Produit professionnel" src={require('./../assets/media/images/banner/img4.jpg')} />
                </div>
                <div className="col-span-1 sm:col-span-2 md:col-span-6 lg:col-span-6">
                  <a className="cursor-default" href="#item">
                    <div className="box-wrapper bg-[#222] p-10 h-full flex flex-col justify-center">
                      {/* <h3 className="font-semibold text-base">Two lines created to express power and design.<br /> We present to you our most prestigious products lines.</h3> */}
                      <p className="font-normal text-sm hidden md:block">Il y a des moments dans la vie qui restent de bons souvenirs, tel que partager un délicieux repas avec les gens que vous aimez.
                        Nous voulons y participer à vous rendre apte de créer des délicieux repas, même s’il s’agit d’une fête familiale.</p>
                      <p>Vous aimez la vie, vous aimez la création, nous sommes FOCUS et vous êtes notre focus, nous sommes ce que nous voulons être.</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="product-category mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Catégories de produits" />
              <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-12 gap-10">
                {menuItemsData[1].children && menuItemsData[1].children[2].children?.map((category: MenuProps, idx: number) => {
                  return (
                    <div key={idx} className="col-span-1 xs:col-span-1 sm:col-span-1 md:col-span-2 lg:col-span-3 group hover:shadow-md duration-300">
                      <Link to={category.path?category.path:"#products"}>
                        <div className="box-wrapper bg-[#222] p-4 flex items-center">
                          <img className="max-w-10 max-h-10" src={category.icon} alt="icon-alt" />
                          <h3 className="font-semibold group-hover:text-focus-primary uppercase text-sm group-hover:scale-110 duration-300 text-center w-full">{category.name}</h3>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>

        <section className="product-inspiration-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent title="Être inspiré" />
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-12 gap-10">
                <div className="col-span-1 sm:col-span-1 md:col-span-3 lg:col-span-6">
                  <LinkBoxComponent src={require('./../assets/media/images/banner/img9.jpg')} />
                </div>
                <div className="col-span-1 sm:col-span-1 md:col-span-3 lg:col-span-6">
                  <LinkBoxComponent src={require('./../assets/media/images/banner/img8.jpg')} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="family-story-section mb-16">
          <div className="section-wraper">
            <div className="content px-8">
              <DividerComponent />
              <a href="#item">
                <div className="grid grid-rows-1 lg:grid-cols-12 ">
                  <div className="lg:col-span-5">
                    <div className="image-space overflow-hidden">
                      <img className="w-full object-cover" src={"https://scontent.ftun16-1.fna.fbcdn.net/v/t1.6435-9/75282268_1511761625628295_8945103163036270592_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=5f2048&_nc_ohc=dUuWEGLi_b8Q7kNvgHr4YwK&_nc_ht=scontent.ftun16-1.fna&oh=00_AYDrZcWgJ_95ez1q2upnTzH_NmaFk86JcCPIwtpt6kM9ww&oe=66782B8E"} alt="alt img" />
                    </div>
                  </div>
                  <div className="lg:col-span-7">
                    <div className="flex flex-col justify-center h-full gap-5 px-5 text-left lg:px-20 py-3 lg:py-6 bg-[#444]">
                      <h3 className="sm:text-2xl text-sm">FOCUS électroménager</h3>
                      <div className="flex flex-col sm:text-sm text-xs md:leading-6">
                        <p className="text-inherit">Découvrez la différence avec FOCUS électroménager. Nos appareils allient performance, innovation et design pour transformer votre cuisine en un espace moderne et fonctionnel. Que vous prépariez un repas rapide en semaine ou un festin pour une grande occasion, FOCUS vous offre les outils nécessaires pour réussir à chaque fois. Nos produits, conçus avec des matériaux de haute qualité et une technologie de pointe, vous garantissent une expérience culinaire exceptionnelle et sans effort. Faites confiance à FOCUS électroménager pour vous accompagner dans chaque moment culinaire, et savourez la simplicité et la joie de cuisiner.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </section>

      </div>
    </div>
  )
}

export default HomePage