

const PageHeaderComponent: React.FC<{src?:string, title?:string, subtitle?:any}> = ({src="https://placehold.co/2048x512", title="", subtitle=null}) => {
  return (
    <div className="page-header mb-5">
      <div className="header-wrapper relative md:min-h-[19rem]">
        <img className="w-full object-cover max-h-[19rem]" src={require('./../../assets/media/images/banner.jpeg')} alt="alt img" />
        {/* <span className="absolute left-0 top-0 right-0 bottom-0 bg-black opacity-80"></span> */}
        <h3 className="absolute left-0 right-0 text-center lg:text-right lg:pr-48 top-[43%] uppercase text-focus-primary font-bold md:text-3xl lg:text-6xl">{title}</h3>
        {subtitle?<div className="absolute left-0 right-0 top-[60%] text-center text-sm lg:text-right lg:pr-48 lg:text-xl uppercase text-black font-semibold">{subtitle}</div>:null}
      </div>
    </div>
  )
}

export default PageHeaderComponent